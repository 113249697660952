(function($) {



    // Box Isotope Client
    var clients = $('.box--isotope--client');
    var clientsFilters = clients.find('.filters');
    var clientsItems = clients.find('.items');

    // init Isotope
    clientsItems.isotope({
        // options
        itemSelector: '.item',
        layoutMode: 'fitRows',
        percentPosition: true,
        masonry: {
            // use outer width of grid-sizer for columnWidth
            columnWidth: '.item-sizer'
        }
    });

    // bind filter button click
    clientsFilters.on('click', 'button', function() {
        var filterValue = $(this).attr('data-filter');
        clientsItems.isotope({ filter: filterValue });
        //console.log(filterValue);
    });

    // change is-checked class on buttons
    clientsFilters.each( function( i, buttonGroup ) {
        var $buttonGroup = $( buttonGroup );
        $buttonGroup.on( 'click', 'button', function() {
            $buttonGroup.find('.is-checked').removeClass('is-checked');
            $( this ).addClass('is-checked');
        });
    });



    // Box Isotope Category
    var cats = $('.box--isotope--category');
    var catsFilters = cats.find('.filters');
    var catsItems = cats.find('.items');

    // init Isotope
    catsItems.isotope({
        // options
        itemSelector: '.item',
        layoutMode: 'fitRows',
        percentPosition: true,
        masonry: {
            // use outer width of grid-sizer for columnWidth
            columnWidth: '.item-sizer'
        }
    });

    // bind filter button click
    catsFilters.on('click', 'button', function() {
        var filterValue = $(this).attr('data-filter');
        catsItems.isotope({ filter: filterValue });
        //console.log(filterValue);
    });

    // change is-checked class on buttons
    catsFilters.each( function( i, buttonGroup ) {
        var $buttonGroup = $( buttonGroup );
        $buttonGroup.on( 'click', 'button', function() {
            $buttonGroup.find('.is-checked').removeClass('is-checked');
            $( this ).addClass('is-checked');
        });
    });



})(jQuery);
